<template>
  <div>
    <FormPaymentReceiptAccount
      v-if="showForm"
      :title-form="objectFormInsert.titleForm"
      :combo-payment="comboFormaPagamento"
      :combo-type="comboTipoPagamento"
      :combo-situation="comboSituacao"
      :object-edit="objectUther"
      @cancelar="cancelar"
      @save="save"
    />
    <div v-else>
      <div class="custom-search d-flex justify-content-start mb-2">
        <b-button class="mr-1" variant="primary" @click="add()">
          Incluir
        </b-button>
      </div>
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="listaFinanceira"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength
        }"
        :total-rows="totalRecords"
        :is-loading.sync="isLoading"
        @on-sort-change="onSortChange"
      >
        <template slot="table-row" slot-scope="props">
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="editFinanceiro(props.row)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="remove(props.row)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="tableConfigs.size"
                :options="[tableConfigs.size]"
                class="mx-1"
                @input="handleChangePage"
              />
              <span class="text-nowrap">
                of {{ tableConfigs.totalPages }} entries
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="tableConfigs.totalElements"
                :per-page="tableConfigs.size"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BPagination
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import FormPaymentReceiptAccount from '@/layouts/components/FormPaymentReceiptAccount.vue'
import mixinsGlobal from '@/mixins'

export default {
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BFormSelect,
    BPagination,
    VueGoodTable,
    FormPaymentReceiptAccount
  },
  mixins: [mixinsGlobal],
  computed: {
    ...mapState('pagamentoRecebimentoFinancialModule', {
      comboSituacao: (state) => state.comboSituacao
    }),
    ...mapState('combosModule', {
      comboFormaPagamento: (state) => state.comboFormaPagamento,
      comboTipoPagamento: (state) => state.comboTipoPagamento
    }),
    ...mapState('othersAdministrativeModule', {
      fullOther: (state) => state.fullOther
    }),
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  mounted() {
    this.cpfAssociado = ''
    this.loadFinancialList()
  },
  data() {
    return {
      tableConfigs: {
        last: false,
        totalPages: 5,
        totalElements: 41,
        size: 10,
        number: 0
      },
      pageConfig: {
        page: 0,
        size: 10,
        sort: 'dataLancamento',
        order: 'asc'
      },
      pageLength: 10,
      isLoading: false,
      totalRecords: 0,
      searchTerm: '',
      listaFinanceira: [],
      showForm: false,
      columns: [
        {
          label: 'Data Lançamento',
          field: 'dataLancamento',
          formatFn: this.formatDateGrid,
          sortable: false
        },
        {
          label: 'Data Quitação',
          field: 'dataQuitacao',
          formatFn: this.formatDateGrid,
          sortable: false
        },
        {
          label: 'Descrição',
          field: 'descricao'
        },
        {
          label: 'Valor',
          field: 'valor',
          formatFn: this.formatCurrencyBrazil
        },
        {
          label: 'Data Vencimento',
          field: 'dataVencimento',
          formatFn: this.formatDateGrid,
          sortable: false
        },
        {
          label: 'Situação',
          field: 'situacao',
          sortable: false
        },
        {
          label: this.$t('Action'),
          field: 'action'
        }
      ],
      pageLength: 10,
      dir: false,
      objectFormInsert: {
        titleForm: 'Cadastro de Contas a Pagar / Receber'
      },
      objectUther: {
        registro: {
          id: null,
          nome: ''
        }
      }
    }
  },
  methods: {
    handleChangePage(page) {
      this.pageConfig.page = page
      this.loadFinancialList(this.module, this.pageConfig)
    },
    onSortChange(params) {
      this.pageConfigdefault()
      this.pageConfig.sort = params[0].field
      this.pageConfig.order = params[0].type
      this.loadFinancialList(this.module, this.pageConfig)
    },
    setDadosAssociado() {
      this.objectUther = {}
      this.objectUther.registro = {
        id: this.fullOther.id,
        nome: this.fullOther.nome
      }
    },
    async loadFinancialList() {
      this.isLoading = true
      this.$store
        .dispatch('pagamentoRecebimentoFinancialModule/listFinancial', {
          id: this.$route.params.id,
          configsPage: this.pageConfig
        })
        .then((result) => {
          this.pageConfig.page = result.data.pageable.pageNumber
          this.tableConfigs.last = result.data.last
          this.tableConfigs.totalPages = result.data.totalPages
          this.tableConfigs.totalElements = result.data.totalElements
          this.tableConfigs.size = result.data.size
          this.tableConfigs.number = result.data.number
          this.listaFinanceira = result.data.content
          this.isLoading = false
        })
        .catch((err) => {
          this.MensagemError(
            'Falha ao carregar lista',
            `${err.response.data.error} / ${err.response.data.message}`
          )
          this.isLoading = false
        })
    },
    async add() {
      this.setDadosAssociado()
      await this.$store.dispatch('combosModule/loadComboFormaPagamento')
      await this.$store.dispatch('combosModule/loadComboTipoPagamento')
      this.showForm = true
    },
    prepareEditObject(data) {
      const objectEdit = {
        id: data.id,
        registro: {
          id: this.fullOther.id,
          nome: this.fullOther.nome
        },
        descricao: data.descricao,
        dataLancamento: data.dataLancamento,
        dataVencimento: data.dataVencimento,
        dataQuitacao: data.dataQuitacao,
        valor: data.valor,
        desconto: data.desconto,
        juro: data.juro,
        multa: data.multa,
        valorTotal: data.valorTotal,
        valorQuitado: data.valorQuitado,
        observacao: data.observacao,
        tipo: {
          id: data.tipo.id,
          nome: data.tipo.descricao
        },
        formaPagamento: {
          id: data.formaPagamento.id,
          nome: data.formaPagamento.descricao
        },
        situacao: data.situacao,
        ativo: data.ativo,
        qtoRepetido: data.qtoRepetido
      }
      return objectEdit
    },
    async editFinanceiro(data) {
      await this.$store.dispatch('combosModule/loadComboFormaPagamento')
      await this.$store.dispatch('combosModule/loadComboTipoPagamento')
      this.objectUther = this.prepareEditObject(data)
      this.showForm = true
    },
    async save(data) {
      const objectSave = this.prepareForSave(data)
      if (objectSave.id) {
        this.edit(objectSave)
        return
      }
      this.insert(objectSave)
    },
    insert(objectSave) {
      this.$store
        .dispatch(
          'pagamentoRecebimentoFinancialModule/insertNewPagamentoRecebimento',
          objectSave
        )
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
              `${response.response.data.errors[0].message}`
            )
            this.showForm = true
            return
          }
          this.showMessageSuccess('Salvo', 'Incluido com sucesso!')
          this.cancelar()
        })
    },
    edit(objectSave) {
      this.$store
        .dispatch(
          'pagamentoRecebimentoFinancialModule/editPagamentoRecebimento',
          objectSave
        )
        .then((response) => {
          if (response.response) {
            this.MensagemError(
              `Falha ao Inserir o campo ${response.response.data.errors[0].fieldName}`,
              `${response.response.data.errors[0].message}`
            )
            this.showForm = true
            return
          }
          this.showMessageSuccess('Salvo', 'Editado com sucesso!')
          this.cancelar()
        })
    },
    cancelar() {
      this.showForm = false
      this.loadFinancialList()
    },
    prepareForSave(data) {
      const objectSave = JSON.parse(JSON.stringify(data))
      const obj = {
        id: objectSave.id,
        registro: objectSave.registro,
        descricao: objectSave.descricao,
        dataLancamento: objectSave.dataLancamento,
        dataVencimento: objectSave.dataVencimento,
        dataQuitacao: objectSave.dataQuitacao,
        valor: objectSave.valor,
        desconto: objectSave.desconto,
        juro: objectSave.juro,
        multa: objectSave.multa,
        valorTotal: objectSave.valor,
        valorQuitado: objectSave.valorQuitado,
        obs: objectSave.obs,
        tipo: {
          id: objectSave.tipo.id ? objectSave.tipo.id : objectSave.tipo
        },
        formaPagamento: {
          id: objectSave.formaPagamento.id
            ? objectSave.formaPagamento.id
            : objectSave.formaPagamento
        },
        situacao: objectSave.situacao,
        ativo: objectSave.ativo,
        qtoRepetido: objectSave.qtoRepetido
      }
      return obj
    },
    async remove(data) {
      console.log('data', data)
      this.$swal({
        title: 'Remover o registro?',
        text: 'Está ação não poderá ser desfeita',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Remover',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch('pagamentoRecebimentoFinancialModule/delete', data.id)
            .then((response) => {
              if (response.data.error) {
                this.MensagemError(
                  'Falha ao remover o registro',
                  response.data.message
                )
                return
              }
              this.showMessageSuccess(
                'Remover',
                'Registro removido com sucesso!'
              )
              this.loadFinancialList()
            })
            .catch((err) => {
              this.MensagemError('Falha ao remover o registro', err.message)
              this.loadFinancialList()
            })
        }
      })
    }
  }
}
</script>
